import * as os from 'os'
import { logDebug } from '@/features/shared/utils/logger'
import { isOnServer } from '@shared/constants/util'

export enum SHUTDOWN_HANDLER {
  HTTP_AGENT = 'http-agent',
}

/**
 * Code below borrowed from @hypercliq/shutdown-cleanup
 */

/**
 * API type
 *
 * These are the types accepted by `process.addListener()` and should be the types
 * accepted by `addSignal()` and `removeSignal()`
 */

type SignalsEvents =
  | 'beforeExit'
  | 'disconnect'
  | 'exit'
  | 'rejectionHandled'
  | 'uncaughtException'
  | 'unhandledRejection'
  | 'warning'
  | 'message'
  | 'newListener'
  | 'removeListener'
  | 'multipleResolves'
  | NodeJS.Signals

/**
 * The handler function to be called when shutdown signals/events are fired.
 *
 * @export
 * @interface HandlerFunction
 */

interface HandlerFunction {
  (signal?: SignalsEvents | Error): unknown
}

const signals: Set<SignalsEvents> = new Set([
  'SIGTERM',
  'SIGHUP',
  'SIGINT',
  'exit',
])

const handlers: Record<string, HandlerFunction> = {}

let shuttingDown = false

const shutdown = async (signal: SignalsEvents | Error): Promise<void> => {
  if (shuttingDown) {
    return
  }
  shuttingDown = true

  logDebug(`Shutting down on ${signal.toString()}`)

  await Promise.all(Object.values(handlers).map((h) => h(signal)))

  logDebug('Shutdown completed')

  const exitCode =
    typeof signal === 'number'
      ? signal
      : signal instanceof Error
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- isolated utility function
        (signal as NodeJS.ErrnoException).errno
      : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- isolated utility function
        os.constants.signals[signal as NodeJS.Signals]

  logDebug(`Shutdown exitCode: ${exitCode}`)

  process.exit(exitCode ?? 1)
}

const attachListenerForEvent = (event: SignalsEvents): NodeJS.Process =>
  process
    .removeAllListeners(event)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- isolated utility function
    .addListener(event as NodeJS.Signals, shutdown)

if (isOnServer()) {
  signals.forEach(attachListenerForEvent)
}

/**
 * Allows to register handlers for certain shutdown signals/events
 * in order to attempt a graceful shutdown.
 *
 * NOTE: it removes any previous registered listeners for the given signals!
 *
 * By default it listens to:
 * `SIGTERM`
 * `SIGHUP`
 * `SIGINT`
 *
 * It also listens to the `exit` event, but keep in mind that `exit` does
 * not allow asynchrounous listeners' operations to complete.
 * https://nodejs.org/dist/latest/docs/api/process.html#process_event_exit
 *
 * It is also possible to add (or remove) other shutdown signals and events.
 *
 * @export
 * @class ShutdownCleanup
 */
export class ShutdownCleanup {
  /**
   * Register a handler function to run at shutdown.
   *
   * @static
   * @param key
   * @param {HandlerFunction} handler
   */
  static registerHandler(
    key: SHUTDOWN_HANDLER,
    handler: HandlerFunction
  ): void {
    handlers[key] = handler
    logDebug(`Handler: ${handler.toString()}`)
  }

  /**
   * Add a shutdown signal/event to listen to.
   *
   * @static
   * @param {SignalsEvents} signal
   * @returns {boolean} `true` if the signal was added
   */
  static addSignal(signal: SignalsEvents): boolean {
    if (signals.has(signal)) return false
    signals.add(signal)
    attachListenerForEvent(signal)
    logDebug(`Added signal: ${signal.toString()}`)

    return true
  }

  /**
   * Remove a shutdown signal/event to listen to.
   *
   * @static
   * @param {SignalsEvents} signal
   * @returns {boolean} `true` if the signal was removed
   */
  static removeSignal(signal: SignalsEvents): boolean {
    if (signals.delete(signal)) {
      process.removeListener(signal, shutdown)
      logDebug(`Removed signal: ${signal.toString()}`)
      return true
    }
    return false
  }

  /**
   * Returns an array of SignalsEvents currently listened to.
   *
   * @static
   * @returns {SignalsEvents[]} an array of `SignalsEvents`
   */
  static listSignals(): SignalsEvents[] {
    return Array.from(signals)
  }
}
