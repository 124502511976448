import { type QueryClient } from '@tanstack/react-query'
import { UserQuery } from '@/features/account/services/User/constants'
import { type User } from '@/features/account/services/User/types'
import { getGuestStoreParamsSSR } from '@/features/shared/utils/dataFetching/guestStoreParams'
import { type Address } from '@/features/account/services/Addresses/types'
import { type GetServerSidePropsContext } from 'next'
import { type BaseUserQuery } from '@/features/account/services/User/graphql/BaseUser.generated'
import { type ShoppingStore } from '@/features/shop/services/ShoppingStore/types'
import { getMembershipType } from '@/features/account/components/Membership/utils'

export const getUser = (queryClient: QueryClient) =>
  queryClient.getQueryData<User>([UserQuery])

export const getUserZip = (
  user: Pick<User, 'customer_addresses' | 'default_shopping_address_id'>
) => getDefaultShoppingAddressWithUser(user)?.zip_code ?? ''

export const getDefaultShoppingAddressWithUser = (
  user: Pick<User, 'customer_addresses' | 'default_shopping_address_id'>
) =>
  user.customer_addresses?.find(
    (address) => address.id === user.default_shopping_address_id
  )

export const getUserHasCaliforniaAddress = (addresses: Address[]) => {
  return addresses.some((address) => address.state === 'CA')
}

export const getIsColoradoAddress = (address: Address) => {
  return address.state === 'CO'
}

type GetUserStateProps = {
  user?: User
  ssrContext: GetServerSidePropsContext
}
export function getUserStateSSR({
  user,
  ssrContext,
}: GetUserStateProps): string {
  const state = user?.id
    ? getDefaultShoppingAddressWithUser(user)?.state
    : getGuestStoreParamsSSR(ssrContext)?.guestAddress?.state
  return state || ''
}

export const mapGqlUserToUser = (
  customer: NonNullable<BaseUserQuery['customer']>
): User => {
  const doNotShareSellTa = customer.privacyPreferences
    ?.find((pref) => pref.path === 'privacy.rights')
    ?.preferences?.find((pref) => pref.id === 'do-not-share-sell')?.value

  let planType: string | null = null
  if (customer.subscription?.plan) {
    const { interval, intervalCount } = customer.subscription.plan
    planType = getMembershipType(interval ?? '', intervalCount ?? 0)
  }

  return {
    id: Number(customer.id),
    universal_id: customer.universal_id,
    name: customer.name,
    email: customer.email,
    phone: customer.phone,
    exempt: customer.exempt,
    has_purchased: customer.has_purchased,
    has_placed_first_order: customer.has_placed_first_order,
    has_placed_marketplace_order: customer.has_placed_marketplace_order,
    order_delivery_type: customer.order_delivery_type,
    default_shopping_address_id: Number(customer.defaultAddress?.id ?? 0),
    auth0_eligible: customer.auth0_eligible,
    do_not_share_sell_ta: !!doNotShareSellTa,
    plan_type: planType,
    marketing_phone: customer.marketingPhone,
    ...(customer.subscription
      ? {
          subscription: {
            id: customer.subscription.id,
            status: customer.subscription.status,
            plan: {
              id: customer.subscription.plan.id,
            },
            type: customer.subscription.type,
          },
        }
      : {}),
    ...(customer.selectedRetailer
      ? {
          store_id: Number(customer.selectedRetailer.id),
          store: {
            id: Number(customer.selectedRetailer.id),
            //TODO: these properties are actually null, so the customer.json type should be updated -- https://app.clickup.com/t/86b1qebu4
            name: customer.selectedRetailer.name ?? '',
            image: customer.selectedRetailer.image ?? '',
            description: customer.selectedRetailer.description ?? '',
            hero_image_url: customer.selectedRetailer.hero_image_url ?? '',
            background_color: customer.selectedRetailer.background_color ?? '',
            circular_image_url:
              customer.selectedRetailer.circular_image_url ?? '',
            require_store_transaction_number_from_shopper:
              customer.selectedRetailer
                .require_store_transaction_number_from_shopper,
          } satisfies ShoppingStore,
        }
      : { store_id: null }),
    ...(customer.selectedLocation
      ? {
          store_location_id: Number(customer.selectedLocation.id),
          metro_id: Number(customer.selectedLocation.metro?.id ?? 0),
          metro: {
            name: customer.selectedLocation.metro?.name ?? '',
          },
        }
      : {
          store_location_id: 0,
          metro: { name: customer.defaultAddress?.metro?.name ?? '' },
          metro_id: Number(customer.defaultAddress?.metro?.id ?? 0),
        }),
    customer_addresses: customer.customerAddresses.map((address) => {
      return {
        ...address,
        id: Number(address.id),
        street2: address.street2 ?? '',
        door_dropoff: !!address.door_dropoff,
        delivery_instructions: address.deliveryInstructions ?? '',
      }
    }),
  }
}
