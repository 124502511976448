import { forwardRef, type Ref, type ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'
import { Button, TextButton } from '@shipt/design-system-buttons'
import { NextLink, type NextLinkProps } from '@/features/shared/elements/Link'
import { Link, type TypographyProps } from '@shipt/design-system-typography'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import { getLinkNewTabAttributes } from '@/features/shared/elements/Link/utils'

const forwardedAsProps = { forwardedAs: 'a' } as const

type LinkProps = NextLinkProps & { opensInNewTab?: boolean }

type ButtonProps = ComponentPropsWithoutRef<typeof Button> & LinkProps

export const ButtonLink = forwardRef(
  (
    {
      href,
      replace,
      shallow,
      opensInNewTab = false,
      ...restProps
    }: ButtonProps,
    ref: Ref<HTMLAnchorElement>
  ) => {
    return (
      <NextLink href={href} replace={replace} legacyBehavior shallow={shallow}>
        <Button
          ref={ref}
          {...restProps}
          {...forwardedAsProps}
          {...getLinkNewTabAttributes(opensInNewTab)}
          isLink
        />
      </NextLink>
    )
  }
)

type TextButtonProps = ComponentPropsWithoutRef<typeof TextButton> & LinkProps

export const TextButtonLink = forwardRef(
  (
    { href, shallow, opensInNewTab = false, ...restProps }: TextButtonProps,
    ref: Ref<HTMLAnchorElement>
  ) => {
    return (
      <NextLink href={href} legacyBehavior shallow={shallow}>
        <TextButton
          ref={ref}
          {...restProps}
          {...forwardedAsProps}
          {...getLinkNewTabAttributes(opensInNewTab)}
          isLink
        />
      </NextLink>
    )
  }
)

export const ExternalButton = forwardRef(
  (
    { children, ...props }: JSX.IntrinsicElements['button'] & TypographyProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <ExternalLink {...props} as={TransparentButton} ref={ref}>
      {children}
    </ExternalLink>
  )
)

const ExternalLink = styled(Link)`
  font-weight: normal;
  white-space: nowrap;
  align-self: start;
`
