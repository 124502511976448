import { useQuery, queryOptions } from '@tanstack/react-query'
import { fetchFeatureFlags } from '@/features/shared/services/FeatureFlags/fetchers'
import { minutesToMilliseconds } from 'date-fns/minutesToMilliseconds'
import {
  type StoreParams,
  useStoreParams,
} from '@/features/shared/utils/dataFetching/storeParams'
import { useUser } from '@/features/account/services/User/hooks'
import { useSession } from '@/features/authentication/utils/authentication/hooks/useSession'

export type FeatureFlagsKey = readonly ['featureFlags', StoreParams]

export const featureFlagsQueryOptions = (params: StoreParams) =>
  queryOptions({
    queryKey: ['featureFlags', params] as const,
    queryFn: fetchFeatureFlags,
  })

export const useQueryFeatureFlags = () => {
  const storeParams = useStoreParams()
  const userId = useUser()?.id ?? 0
  const { isFullyAuthenticated } = useSession()

  return useQuery({
    ...featureFlagsQueryOptions({ ...storeParams, user_id: userId }),
    placeholderData: (previousData) => previousData,
    enabled: isFullyAuthenticated ? Boolean(userId) : true,
    staleTime: minutesToMilliseconds(10),
  })
}
