import { type GetServerSidePropsContext } from 'next'
import ServerCookies from 'cookies'
import { UNIVERSAL_ID } from '@shared/constants/segment'
import Cookies from 'js-cookie'
import { isOnServer } from '@shared/constants/util'
import { type User } from '@/features/account/services/User/types'
import { type AristotleExperiments } from '@/features/shared/services/Experiments/types'
import { type ExperimentType } from '@/features/shared/services/Experiments/constants'

const getUniversalIdFromSSRCookies = (
  ssrContext: GetServerSidePropsContext
) => {
  const cookies = new ServerCookies(ssrContext.req, ssrContext.res)
  const universal_id = cookies.get(UNIVERSAL_ID)
  if (universal_id) return universal_id
  return ''
}

type UniversalIdForStoreParams = {
  ssrContext?: GetServerSidePropsContext
  user?: User
}

export const getUniversalId = (params?: UniversalIdForStoreParams): string => {
  const { user, ssrContext } = params ?? {}
  if (user?.universal_id) return user.universal_id
  if (isOnServer() && ssrContext) {
    return getUniversalIdFromSSRCookies(ssrContext)
  } else return Cookies.get(UNIVERSAL_ID) || ''
}

export const getExperimentStatus = (
  experiments: AristotleExperiments | undefined,
  key: ExperimentType
) => {
  const value = experiments?.[key]?.value
  const active =
    Boolean(experiments?.[key]) && value !== 'control' && value !== 'off'

  return { value, active }
}
