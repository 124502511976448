export enum PromotionTypes {
  OrderDiscount = 'next-order-discount',
  DeliveryFeeMember = 'delivery-fee-member',
  DeliveryFeeNonMember = 'delivery-fee-non-member',
  SpendGet = 'spend_get',
  Membership = 'membership',
}

export type AvailablePromotionsResponse = {
  promotions?: Promotion[]
}

export type AvailablePromotionsPayload = {
  availablePromotions: Promotion[]
  dollarTotalQualifyingCredit: number
}

type PromotionThreshold = {
  progress?: number
  remaining_amount?: number
  threshold_amount?: number
  threshold_type?: string
  effect_currency?: string
  effect_type?: string
  effect?: number
}

export type Promotion = {
  id?: number | null // campaigns id for tracking
  audience?: 'global' | 'targeted'
  promotion_id?: string | null //uuid of promotion
  type?: PromotionTypes | null
  display_name?: string | null
  display_description?: string | null
  disclaimer_body?: string | null
  disclaimer_header?: string | null
  full_terms_title?: string | null
  full_terms_url?: string | null
  terms?: string | null
  valid_through?: string | null
  valid_through_string?: string | null
  thresholds?: PromotionThreshold[] | null
  promotion_params?: PromotionParams | null
  member_only?: boolean
  available_stores?: string[] | null
  offer_headline?: string | null
  button_cta?: string | null
  display_custom_copy?: string | null
  progress_header?: string | null
  progress_subheader?: string | null
  progress?: number | null
}

type PromotionParams = {
  category_id?: number | null
  id?: number | null // internal table name
  images?: { image_url: string }[] | null
  is_marketing_category?: boolean
  position?: number | null
  ui_promotion_type?: string | null
  feature_promotion_group_id?: number | null
  original_price?: number | null
  hide_original_price?: boolean | null
  search_url?: string | null
  landing_page_id?: string | null
  relative_link?: string | null
}

export type OrderPromotions = {
  promotion_key?: string | null
  credit?: number | null
  context?: string | null
  display_description?: string | null
  display_name?: string | null
  full_terms_title?: string | null
  full_terms_url?: string | null
  thresholds?: string | null
  type?: string | null
  valid_through?: string | null
  valid_through_string?: string | null
  image_url?: string | null
  id?: number | null
  progress?: number | null
  progress_header?: string | null
  progress_subheader?: string | null
}
