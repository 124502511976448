import { cmsRoutes, routes } from '@shared/constants/routes'

export const MEMBERSHIP_POPOVER_LINKS = [
  { url: routes.PRICING.url, name: 'See all plans' },
  { url: routes.STUDENT.url, name: 'Student Membership' },
  {
    url: 'https://www.shipt.com/lp/snap/',
    name: 'SNAP EBT Membership',
  },
  {
    url: 'https://www.shipt.com/gift/',
    name: 'Gift Cards',
  },
]
export const MAKE_MONEY_LINKS = [
  { url: cmsRoutes.SHOPPER.BE_A_SHOPPER, name: 'Be a shopper' },
  { url: cmsRoutes.DRIVER.BE_A_DRIVER, name: 'Be a driver' },
  { url: `${routes.PARTNER.url}/affiliate`, name: 'Be an affiliate' },
]

export const SAME_DAY_DELIVERY_LINKS = [
  { url: cmsRoutes.DELIVERY.GROCERY_DELIVERY, name: 'Grocery delivery' },
  { url: cmsRoutes.DELIVERY.ALCOHOL_DELIVERY, name: 'Alcohol delivery' },
  {
    url: cmsRoutes.DELIVERY.PET_SUPPLIES_DELIVERY,
    name: 'Pet supplies delivery',
  },
  {
    url: cmsRoutes.DELIVERY.ELECTRONICS_DELIVERY,
    name: 'Electronics delivery',
  },
  {
    url: cmsRoutes.DELIVERY.HOUSEHOLD_ESSENTIALS_DELIVERY,
    name: 'Household essentials delivery',
  },
  { url: routes.CITIES.url, name: 'City delivery near you' },
  { url: routes.DELIVERY.url, name: 'View all' },
]
