export enum VisaBenefitType {
  Traditional = '1',
  Signature = '2',
  Infinite = '3',
}
export interface VisaBenefitTier {
  id?: VisaBenefitType | null
  name?: string | null
  durationMonths?: number | null
  durationText?: string | null
  discountedDurationMonths?: number | null
  discountedDurationText?: string | null
  totalDurationMonths?: number | null
  totalDurationText?: string | null
  existingDurationMonths?: number | null
  existingDurationText?: string | null
  imgUrl?: string | null
  fallbackUrl?: string | null
  isMax?: boolean | null
}
export type VisaBenefitTiers = {
  [P in VisaBenefitType]: VisaBenefitTier
}
type VisaBenefitFailureStatus = 'ineligible' | 'already_redeemed'
type VisaBenefitStatus =
  | 'pending'
  | 'enrolled'
  | 'eligible'
  | VisaBenefitFailureStatus

interface VisaPromoMetadata {
  display_description?: string | null
  display_name?: string | null
  terms_and_conditions?: string | null
}
export interface VisaBenefitDetails {
  card_last_four?: string | null
  customer_id?: string | null
  correlation_id?: string | null
  status?: VisaBenefitStatus
  token?: string | null
  type?: VisaBenefitType | null
  user_type?: 'new' | 'existing' | 'upgrade' | 'downgrade' | 'sidegrade' | null
  promo_id?: string | null
  promo_issuer?: string | null
  promo_metadata?: VisaPromoMetadata | null
  issuer_logo_url?: string | null
}
