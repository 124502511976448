import { isLocal } from '@/features/shared/utils/environment'
import { scripts } from '@/lib/ThirdPartyScripts/scripts'
import { shouldDisableAnalytics } from '@/features/shared/analytics/utils'

const gtmContainerUrl = process.env.NEXT_PUBLIC_GTM_CONTAINER_URL
const disableSegmentOnClient = shouldDisableAnalytics()
const nonce = process.env.NEXT_PUBLIC_NONCE_PLACEHOLDER

interface Props {
  disableSegment: boolean
  isPCI: boolean
}

/**
 * Contains script tags for non-pci-approved 3rd party scripts. This should only be
 * loaded on non-PCI pages.
 */
function NonPCIApprovedScripts({
  disableSegment,
}: Readonly<Pick<Props, 'disableSegment'>>) {
  return (
    <>
      <link rel="preconnect" href="https://cdn.segment.io" />
      {Boolean(gtmContainerUrl) && (
        <link rel="preconnect" href={gtmContainerUrl} />
      )}
      {process.env.NEXT_PUBLIC_DYNATRACE_SRC && (
        <script
          nonce={nonce}
          type="text/javascript"
          src={process.env.NEXT_PUBLIC_DYNATRACE_SRC}
          crossOrigin="anonymous"
          defer
        ></script>
      )}
      {!(disableSegment || disableSegmentOnClient) && (
        <script
          type="text/javascript"
          id="segment"
          nonce={nonce}
          // eslint-disable-next-line shipt/no-unsanitized-inner-html -- We are setting JS here; it cannot be sanitized.
          dangerouslySetInnerHTML={{
            __html: scripts.segment,
          }}
        />
      )}
      <script
        type="text/javascript"
        nonce={nonce}
        // eslint-disable-next-line shipt/no-unsanitized-inner-html -- We are setting JS here; it cannot be sanitized.
        dangerouslySetInnerHTML={{
          __html: scripts.mouseflow,
        }}
      />
      <script
        type="text/javascript"
        nonce={nonce}
        // eslint-disable-next-line shipt/no-unsanitized-inner-html -- We are setting JS here; it cannot be sanitized.
        dangerouslySetInnerHTML={{
          __html: scripts.contentsquare,
        }}
      />
      {!isLocal && (
        <>
          <script
            type="text/javascript"
            nonce={nonce}
            // eslint-disable-next-line shipt/no-unsanitized-inner-html -- We are setting JS here; it cannot be sanitized.
            dangerouslySetInnerHTML={{
              __html: scripts.branch,
            }}
          />
          <script
            type="text/javascript"
            src="https://resources.digital-cloud-west.medallia.com/wdcwest/3490/onsite/embed.js"
            nonce={nonce}
            async
          />
        </>
      )}
      {Boolean(gtmContainerUrl) && (
        <script
          id="gtm-bootstrapper"
          type="text/javascript"
          nonce={nonce}
          // eslint-disable-next-line shipt/no-unsanitized-inner-html -- We are setting JS here; it cannot be sanitized.
          dangerouslySetInnerHTML={{
            __html: scripts.googletagmanager,
          }}
        />
      )}
    </>
  )
}
export function ThirdPartyScripts({ disableSegment, isPCI }: Readonly<Props>) {
  return (
    <>
      <script
        async
        src="/scripts/fkrk.js"
        type="text/javascript"
        nonce={nonce}
      />
      {/* The scripts above are evergreen and will be loaded on all pages, including PCI secured pages */}
      {/* The scripts below are not approved for the PCI environment, and should only be loaded on non-pci pages */}
      {!isPCI && <NonPCIApprovedScripts disableSegment={disableSegment} />}
    </>
  )
}
