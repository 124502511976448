import { useCallback, useState } from 'react'
import {
  queryOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import {
  useUserAddressParams,
  useUserId,
} from '@/features/account/services/User/hooks'
import {
  fetchShoppingStoreById,
  fetchShoppingStores,
} from '@/features/shop/services/ShoppingStore/fetchers'
import {
  type ShoppingStoreParams,
  type FetchShoppingStoresParams,
} from '@/features/shop/services/ShoppingStore/types'
import { useSession } from '@/features/authentication/utils/authentication/hooks/useSession'

export type ShoppingStoreByIdQueryKey = readonly [string, { storeId: number }]
const shoppingStoresByIdQueryOptions = (storeId: number) =>
  queryOptions({
    queryKey: ['Shopping store by id', { storeId }] as const,
    queryFn: fetchShoppingStoreById,
  })

export const useQueryShoppingStoreById = (storeId: number, enabled = true) => {
  const userId = useUserId()

  return useQuery({
    ...shoppingStoresByIdQueryOptions(storeId),
    enabled: Boolean(storeId && userId && enabled),
  })
}

export type ShoppingStoresKey = readonly [string, ShoppingStoreParams]

type ShoppingStoresProps = {
  zip?: string
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps']
  isDisabled?: boolean
}

export const shoppingStoresQueryOptions = (
  params: ShoppingStoreParams,
  additionalParams?: { preferredStoreLocationId: number }
) =>
  queryOptions({
    queryKey: ['Stores and Pickups', params] as const,
    queryFn: (ctx) => fetchShoppingStores(ctx, additionalParams),
  })

export const useQueryShoppingStores = ({
  zip,
  isDisabled,
  notifyOnChangeProps,
}: ShoppingStoresProps = {}) => {
  const userId = useUserId()
  const params = useUserAddressParams({ zip })
  const { isSessionLoading, isSessionValid } = useSession()

  return useQuery({
    notifyOnChangeProps,
    ...shoppingStoresQueryOptions(params),
    enabled:
      !isDisabled &&
      !isSessionLoading &&
      Boolean(
        isSessionValid
          ? userId && params && 'address_id' in params && params.address_id
          : params && 'address_zip_code' in params && params.address_zip_code
      ),
  })
}

export const useQueryShoppingStoresByAddressId = (addressId: number) => {
  const userId = useUserId()
  return useQuery({
    ...shoppingStoresQueryOptions({ address_id: addressId }),
    enabled: Boolean(userId && addressId),
  })
}

export const useFetchShoppingStores = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const fetchStores = useCallback(
    async (query: FetchShoppingStoresParams) => {
      const address = 'address' in query && query.address
      const addressId = 'address_id' in query && query.address_id
      setIsLoading(true)
      try {
        const params = {
          ...(addressId && { address_id: addressId }),
          ...(address && {
            ...('street1' in address && {
              address_line_1: address.street1,
              address_city: address.city,
              address_state: address.state,
            }),
            address_zip_code: address.zip_code,
          }),
        }
        return await queryClient.fetchQuery(shoppingStoresQueryOptions(params))
      } finally {
        setIsLoading(false)
      }
    },
    [queryClient]
  )
  return { fetchStores, isLoading }
}
