import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type AristotleExperiments,
  type AristotleResponse,
} from '@/features/shared/services/Experiments/types'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type ExperimentsQueryKey } from '@/features/shared/services/Experiments/queries'
import { removeFalseyValues } from '@/features/shared/utils/removeFalseyValues'
import {
  getObjectEntries,
  getObjectFromEntries,
} from '@/features/shared/utils/types'
import { defaultExperiments } from '@/features/shared/services/Experiments/defaultExperiments'

export const fetchAllExperiments = async (
  context: QueryFunctionContext<ExperimentsQueryKey>
): Promise<AristotleExperiments> => {
  const [
    ,
    {
      storeParams,
      has_placed_first_order,
      has_placed_marketplace_order,
      has_purchased,
      has_subscription,
      is_exempt,
      plan_id,
      user_id,
      subscription_type,
    },
  ] = context.queryKey
  const signed_in = Boolean(user_id)
  // Aristotle doesn't like null query params
  const params = {
    ...removeFalseyValues(storeParams),
    signed_in,
    has_placed_first_order,
    has_placed_marketplace_order,
    has_purchased,
    has_subscription,
    is_exempt,
    plan_id,
    subscription_type,
  }

  const response = await apiGet<AristotleResponse>({
    config: { url: 'aristotle/v2/all', params },
    context,
    fetcherName: 'fetchAllExperiments',
  })

  return getObjectFromEntries(
    getObjectEntries(defaultExperiments).map(([experimentType, value]) => {
      return [experimentType, response.variations[experimentType] ?? value]
    })
  )
}
