import { useQuery } from '@tanstack/react-query'
import {
  TwoFactorPreferenceQuery,
  TwoFactorChannelsQuery,
} from '@/features/authentication/services/TwoFactorAuth/constants'
import { toError } from '@/features/shared/utils/dataFetching/utils'
import { trackUserLoginError } from '@/features/shared/analytics/users'
import {
  fetchTwoFactorAuthChannels,
  fetchTwoFactorAuthPreference,
} from '@/features/authentication/services/TwoFactorAuth/fetchers'
import { useEffect } from 'react'
import {
  useSession,
  SessionType,
} from '@/features/authentication/utils/authentication/hooks/useSession'

export const useQueryTwoFactorAuthPreference = () => {
  const { isSessionValid, sessionType } = useSession()

  return useQuery({
    queryKey: [TwoFactorPreferenceQuery],
    queryFn: fetchTwoFactorAuthPreference,
    enabled: Boolean(isSessionValid && sessionType === SessionType.Legacy),
  })
}

export const useQueryTwoFactorAuthChannels = (enabled = false) => {
  const query = useQuery({
    queryKey: [TwoFactorChannelsQuery],
    queryFn: fetchTwoFactorAuthChannels,
    enabled,
  })
  useEffect(() => {
    if (query.error) trackUserLoginError(toError(query.error))
  }, [query.error])

  return query
}
