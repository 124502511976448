import { useFetchUserQuery } from '@/features/account/services/User/hooks'
import { useCallback } from 'react'
import { useSession } from '@/features/authentication/utils/authentication/hooks/useSession'
import { handleAuth0Login } from '@/features/shared/utils/handleAuth0Login'

export const useSyncUser = () => {
  const fetchUserQuery = useFetchUserQuery()
  const { isSessionLoading, isFullyAuthenticated, checkSession } = useSession()

  return useCallback(async () => {
    await checkSession()
    // This block repopulates the customer state if auth data is present,
    if (isSessionLoading) return
    if (isFullyAuthenticated) {
      fetchUserQuery().then(() => {
        // Checks for auth0 login completed cookie and sends tracking event if needed
        handleAuth0Login()
      })
    }
  }, [fetchUserQuery, isSessionLoading, isFullyAuthenticated, checkSession])
}
